// Grid system
.content {
  @include grid-row;
  width: 100%;
}
.main {
  // @include grid-column($main-sm-columns);
  .sidebar-primary & {
    // @include grid-column($main-sm-columns - $sidebar-sm-columns);
    @include breakpoint(medium down) {
      // @include grid-column($main-sm-columns);
    }
  }
}
.sidebar {
  @include grid-column($sidebar-sm-columns);
  @include breakpoint(medium down) {
    @include grid-column($main-sm-columns);
  }
}
