.about {
	.landing {
		display:flex;
		max-width: 45em;
		@extend .clearfix;
		img {
			margin-bottom: 2rem;
		}
		@include breakpoint(medium){
			display:block;
			.medium-6 {
				// float:left;
				// width: 60%;
			}
			.medium-4 {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	.landing .entry-content {
		font-size: 1.25em;
	}

	.bios.row {
		justify-content: center;
	}
	.bio hr {
		width: 55%;
		margin: 3rem auto;
	}
	.bio.row, .bio.row .row {
		justify-content: space-around;
	}
	.bio.row .row{
		justify-content: space-around;
	}

	.entry-image img{
		box-shadow: 0px 2px 19px -4px;
	}
	
	.bio:nth-child(even){
		@include breakpoint(medium){
			.entry-image {
				order: 1;
			}
		}
	}

	.name {
		@extend .typewriter;
		margin-left: auto;
	}
	.entry-image {
		margin-bottom: 1rem;
	}
}