.youtube, .vimeo {
	position: relative;
	& .info {
		position: absolute;
		top: 0; left:0; right:0; bottom: 0; 
		padding: 1em;
		display:flex;
		flex-direction: column;
		justify-content: flex-end;
		visibility: hidden;
		opacity: 0;
		transition:visibility linear 0s, background .2s, opacity .2s linear;
		@include breakpoint(medium){
			top: initial;
			padding: 1em 4em;
		}

	}
	&:hover {
		transition: background .2s;
		.info {
			opacity: 1;
			visibility: visible;
			background: transparentize($white, .3);
			transition: visibility linear 1s, opacity .2s, background .2s;
		}
	}
}