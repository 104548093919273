.filter-button-group {
	button {
		background-color: $white;
		color: lighten($black, 20%);
		border: 1px solid lighten($black, 20%);
		transition: color .1s, background-color .1s;
		padding: .5em 1em; 
		cursor: pointer;
		margin: 0 .5em;
		border-radius: .3em;

		&:hover, &.active {
			background-color: lighten($black,15%);
			color: $white;
			border: 1px solid $black;
		}
	}
}