body {
    // display:flex;
    // flex-direction:column;
}
.off-canvas-wrapper {
	// flex: 1;

	border-top: .5rem black solid;
	border-bottom: .5rem black solid;
}
.off-canvas-wrapper-inner {
	// height: 100%;
	// display: flex;
	// flex-direction: column;
}
.off-canvas-content {
	margin-top: 2em;
	flex: 1;
	display: flex;
	flex-direction: column;
	z-index: 0;
	box-shadow:none;
}
.wrap.container {
	flex:1;
	overflow: hidden;
}

.off-canvas.position-left {
	bottom: 0;
	background: $black;
	a {
		color: $white;
		padding: 1em;
		&:hover {
			background: $white;
			color: $black;
			border: 1px solid $black
		}
	}
}