// Search form
.search-form {

}
.search-form label {
  font-weight: normal;
}
.search-form .search-field {
}
.search-form .search-submit {
  @extend .button;
}
