.brandPhoto {
	display:flex; flex:1; 
    justify-content:center;
	margin-left: auto;
	margin-right: auto;
    .inner {
        display:flex;
        opacity: 0;
        flex:1;
        transition: opacity .82s;
        justify-content:center;
     }
}

.team {
    height: 470px;
    width: 100%;
    background-image: url(../images/team.jpg);
    background-size: contain;
    background-position: center;
    background-color: white;
}

.teamImg {
    position: relative;
    height: 40vw;
    width: 33.3333%;
    // max-width: 400px;
    max-height: 565px;
    min-height: 210px;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .name {
        flex: 0 1 auto;
        z-index: 10;
        margin: .1rem .1rem;
        padding: 0 .1rem;
        font-size: 1rem;
        color: $white;
        text-shadow: 0 0 .51em $black;
        transition: color .2s, text-shadow .2s;
        // text-decoration: underline;
        // text-transform: capitalize;
        letter-spacing: .3em;
        line-height: 1.1;
        // border-left: 3px solid $white;
        @include breakpoint(medium){
            font-size: 4rem;
        }
        @include breakpoint(large){
            font-size: 5rem;
        }

    }
    &:hover .name {
            color: $black;
            text-shadow: 0 0 .51em $white;
    }
    & .after {
        opacity: 0;
        transition: opacity .5s;
    }

    &:hover .after {
        opacity: 1;
    }

    & .before, & .after {
        content: '';
        top: 0;
        bottom: 0;
        left:0;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
    }
}

// .ryan {
//     & .after {
//         background-image: url(../images/ryan-profile.jpg), radial-gradient(white, white);
//     }

//     & .before {
//         background-image: url(../images/ryan-front.jpg), radial-gradient(white, white);
//     }
// }

// .ken {
//     & .after {
//         background-image: url(../images/ken-profile.jpg), radial-gradient(white, white);
//         transform: rotateY(180deg);   
//     }

//     & .before {
//         background-image: url(../images/ken-front.jpg), radial-gradient(white, white);
//     }
// }

// .jon {
//     & .after {
//         background-image: url(../images/jon-profile.jpg), radial-gradient(white, white);
//     }

//     & .before {
//         background-image: url(../images/jon-front.jpg), radial-gradient(white,white);
//     }
// }