.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

#site-header {
    .brand {
        display: block;
        width: 200px;
        background-position: center;
        height: auto;
    }
   
}
#widemenu {
        background: transparent;
        z-index: 10;
        position: relative;
        height: 13vw;
        min-height: 110px;
        max-height: 400px;
        .top-bar-center {
            font-size: 9vw;
            a {
                color: black;
            }
            // height: 150px;
            // width: 50%;
        }
        .brand {
            width: 100%;
            height: 100%;
        }
}

.menu {
    .top-bar-left & {
        justify-content: flex-end;
    }
    .top-bar-right & {
        justify-content: flex-start;
    }
}