
.row {
    position: relative;
}

.content {
    margin-top: 3rem;
    background: $white;
    flex: 1;
}

.brand {
    background-image: url(../images/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -10000px;
    width: 100%;
    height: auto;
}

.shadow {
    box-shadow: 0 .5em 3em -.81em #0a0a0a;
}

.credits {
    // display:table;
    // max-width: 450px;
    li {
        display: block;
        margin-bottom: .6em;

        .credit-title, .credit-value {
            display: table-cell;
            line-height: 1.4;
        }

        .credit-title {
            line-height: 1;
            min-width: 120px;
            text-align: right;
            padding-right: 1em;
            font-size: .9em;
            vertical-align: top;
            text-transform: lowercase;
        }

        .credit-value {
            vertical-align: top;
        }
    }
}

.terms-list {
    margin-top: ms(0);

    a {
        text-transform: lowercase;
        padding: .5em;
        border-radius: .3em;
        transition: background .2s;
        background: $brand-light-purple;
        color: $white;

        &:hover {
            color: $brand-light-purple;
            background: $white;
        }
    }
}

.list-webisodes {
    margin-left: 0;
    list-style-type: none;
    display: flex;
    align-items: flex-end;

    @include flex-grid-column(12);

    @include flex-grid-layout(1, ".item-webisode");

    @include breakpoint(medium) {
        @include flex-grid-layout(2, ".item-webisode");
    }


    @include breakpoint(large) {
        @include flex-grid-layout(3, ".item-webisode");
    }


    .item-webisode {
        padding:1em;
    }
}

.video-library--video {
    display: flex;
    margin-bottom: 4rem;

    @include flex-grid-column(6);

    transition: background .2s;
    padding-top: 1em;

    &:hover {
        background: $white;

        img {
            transform: scale(0.95);
        }

        a {
            color: $brand-primary;
        }

        @include breakpoint(medium) {}
    }

    a {
        color: $white;

        //lighten($black, 10%);
        // width: 100%;
        transition: color .2s;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // align-items: center;
   
    }

    img {
        height: auto;
        transition: transform .2s;
    }

    .info {
        // display: none;
        width: 100%;

        @include breakpoint(59em) {
            display: initial;
        }


        .video-description {
            display: none;
        }
    }

    @include breakpoint(28em) {
        @include flex-grid-column(4);
    }


    @include breakpoint(33em) {
        @include flex-grid-column(3);
    }


    @include breakpoint(50em) {
        @include flex-grid-column(2);

        // width: 33.333%;
    }
}

#featured-content {
    .section-title {
        margin-top: 5rem;

        line-height: 1.4;
        font-weight: 800;
        text-transform: uppercase;
        background: #3d3d3d;
        color: $white;
        padding: .4em .5em .2em;
        text-align:center;
        @include breakpoint(medium){
            font-size: 2rem;
        }
        @include breakpoint(large){
            font-size: 4rem;
        }

    }
	.lead {
		margin-top: 1em;
	}
}

.typewriter {
	font-family: 'Special Elite', 'Cutive','Life Savers',  serif;
}

.home .wp-post-image, iframe, .play + img {

	border: 5px solid transparent;
	box-shadow: 0 0 0 5px lighten($black,10%);
}

.category .wp-post-image {
	border: none;
}



.single article {
	margin-bottom: 2em;
}

.main-video {
	.entry-content {
		justify-content: center;
		@include breakpoint(medium){
			justify-content: space-around;
		}
	}
	.video-info {
		margin-top: 1rem;
		padding: 0 1rem;
	}
	aside {
		text-align: center;
		@include breakpoint(medium){
			text-align: initial;
		}

	}
}

.home-videos {
    .menu {
        flex-wrap: wrap;
        li {
            margin-top: 1em;
        }
    }
}


.border {
    border: 1px solid;
}